import React from 'react';
import Img from "gatsby-image/withIEPolyfill";
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default ({ logo, url, title, text }) => (
  <SectionWrapper className={styles.container}
    id={url}>
    <div className={styles.logoContainer}>
      <Img imgStyle={{ objectFit: 'contain' }} 
        objectFit='contain'
        className={styles.imageContainer}
        fluid={logo.fluid}
        alt={title}/>
    </div>
    <div className={styles.textContainer}>
      <Typography variant='h4'
        weight='bold'
        className={styles.titleContainer}>
        {title}
      </Typography>
      <Typography variant='subtitle2'
        weight='light'
        className={styles.subtitleContainer}>
        {text}
      </Typography>
    </div>
  </SectionWrapper>
);