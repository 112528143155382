import React from 'react';
import clsx from 'clsx';
import Img from "gatsby-image/withIEPolyfill";
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default ({
  title,
  description,
  selected,
  image
}) => (
  <div className={clsx(
      styles.showcaseElement,
      selected ? '' : styles.showcaseElementHidden
    )}>
    <Img imgStyle={{ objectFit: 'cover', borderRadius: '16px' }}
      className={styles.imageContainer}
      fluid={image}
      alt={title}/>
    <Typography variant='h4'
      color='dark'
      weight='bold'
      align='left'
      className={styles.showcaseTitleContainer}>
      {title}
    </Typography>
    <Typography variant='subtitle2'
      weight='light'
      align='left'
      className={styles.showcaseDescriptionContainer}>
      {description}
    </Typography>
  </div>
);