import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default ({
  onClick = () => {},
  selected = false,
  Tile,
  text
}) => (
  <Grid item xs={6} sm={4} md={2} lg={6}
    onClick={onClick}
    className={clsx(
      styles.container,
      selected ? styles.containerSelected : ''
    )}>
    <Tile selected={selected}
      className={styles.imageContainer}
      fill={styles.stroke}/>
    <Typography variant='h6'
      align='center'
      weight='bold'
      color={selected ? 'secondaryDark' : 'main'}
      className={styles.text}>
      {text}
    </Typography>
  </Grid>
);