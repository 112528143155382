import React from 'react';
import { Grid } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        background: imageSharp (fluid: {
          originalName: { eq: "Services-Hero.png" }
        }) {
          fluid(maxWidth: 1736, maxHeight: 820, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ background }) => (
      <SectionWrapper className={styles.container}>
        <Grid item xs={12} md={12} className={styles.overviewContainer}>
          <Typography
            variant='h1'
            weight='bold'
            align='center'
            className={styles.title}>
            {i18n('Explore the digital world powered by OBI+')}
          </Typography>
        </Grid>
        <Grid item className={styles.imageWrap}
          xs={12} sm={12} md={12} lg={12}>
          <Img imgStyle={{ objectFit: 'contain' }}
            objectFit='contain'
            className={styles.imageContainer}
            fluid={background.fluid}
            alt={i18n('OBI+ provides digital services through connected car')}/>
        </Grid>
      </SectionWrapper>
    )
  }/>
);