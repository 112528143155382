import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import Banner from '../Banner';
import Tile from '../Tile';
import Narrow from '../NarrowEntry';
import Screenshot from './Screenshot';
import RelevantIndustries from '../RelevantIndustries';
import template from './template';
import styles from './styles.module.sass';

export default () => {
  const [ selected, setShowcase ] = React.useState(0);
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: imageSharp(fluid: {originalName: {eq: "driver-logo-new-2x.png"}}) {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot0: imageSharp(fluid: {originalName: {eq: "Fleet-Driver-1.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot1: imageSharp(fluid: {originalName: {eq: "Fleet-Driver-2.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot2: imageSharp(fluid: {originalName: {eq: "Fleet-Driver-3.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot3: imageSharp(fluid: {originalName: {eq: "Fleet-Driver-4.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot4: imageSharp(fluid: {originalName: {eq: "Fleet-Driver-5.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot5: imageSharp(fluid: {originalName: {eq: "Fleet-Driver-6.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      `}
      render={({ logo, ...data }) => (
        <>
          <Banner url={i18n('_hash:driver-app')} logo={logo}
            title='Drive'
            text={i18n('A connected car service valuable for every driver with applications available on both iOS and Android devices')}/>
          <SectionWrapper className={styles.container}>
            <Grid container className={styles.contentContainer} justifyContent='space-between' alignContent='space-between'>
              <Grid item xs={12} sm={12} lg={4}
                className={styles.showcaseTextNarrow}> 
                {template.map((t, key) => (
                  <Narrow key={key}
                    {...t}
                    selected={selected === key}
                    onChange={() => setShowcase(key)}/>
                ))}
              </Grid>
              <Grid item container xs={12} sm={8} lg={5}
                className={styles.showcaseText}
                justifyContent='center'
                alignContent='center'
                alignItems='center'>
                <Typography variant='h4'
                  color='dark'
                  weight='bold'
                  className={styles.showcaseTitleContainer}>
                  {template[selected].title}
                </Typography>
                <Typography variant='subtitle2'
                  weight='light'
                  align='left'
                  className={styles.showcaseDescriptionContainer}>
                  {template[selected].description}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} lg={3}
                className={styles.showcaseImageContainer}>
                {template.map((t, i) => (
                  <Screenshot key={i}
                    selected={selected === i}
                    image={data[`shot${i}`]}
                    alt={t.title}/>
                ))}
              </Grid>
              <Grid container item sm={12} lg={4}
                direction='row'
                justifyContent='space-between'
                className={styles.tilesContainer}>
                {template.map((t, i) => (
                  <Tile key={i}
                    Tile={t.tile}
                    selected={i === selected}
                    onClick={() => setShowcase(i)}
                    text={t.title}/>
                ))}
              </Grid>
            </Grid>
          </SectionWrapper>
          <RelevantIndustries industries={[{
              text: i18n('Drivers'),
              url: i18n('_url:drivers')
            }, {
              text: i18n('Fleet management'),
              url: i18n('_url:fleet-management')
            }, {
              text: i18n('Repair shops'),
              url: i18n('_url:repair-shop')
            }, {
              text: i18n('Leasing'),
              url: i18n('_url:leasing')
            }, {
              text: i18n('Dealerships'),
              url: i18n('_url:dealership')
            }]}/>
        </>
      )}/>
  )
}